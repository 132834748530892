var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var detectHover = {
  update: function update() {
    if (typeof window !== "undefined" && typeof window.matchMedia === "function") {
      detectHover.hover = window.matchMedia("(hover: hover)").matches;
      detectHover.none = window.matchMedia("(hover: none)").matches || window.matchMedia("(hover: on-demand)").matches;
      detectHover.anyHover = window.matchMedia("(any-hover: hover)").matches;
      detectHover.anyNone = window.matchMedia("(any-hover: none)").matches || window.matchMedia("(any-hover: on-demand)").matches;
    }
  }
};
detectHover.update();
exports.default = detectHover;
export default exports;
export const __esModule = exports.__esModule;